
import React from 'react';
import { Link, navigate } from 'gatsby';
import { localizer } from '../util/localizer';

export default function Eks(props) {
  const ctx = props.pageContext;
  return (
    <div>
      <h1>Test Error Page</h1>
      <div className="p-12-20">
        <Bong ctx={ctx} />
      </div>
      <div className="p-12-20">
        <Link to="/">Go Home</Link>
      </div>
    </div>
  );
};

function Bong(props) {
  const localized = localizer(props.ctx);
  function goBoom() {
    const errpage = localized('/generalError/');
    console.log('Boom! Navigate to error page at', errpage);
    navigate(errpage, {state: {description: `A snafu has transpired at ${new Date().toLocaleTimeString()}`}});
  }
  return <button onClick={(e) => { goBoom(); }}>{props.message || 'Create an error here'}</button>;
};
